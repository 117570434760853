import { Controller } from "stimulus"

export default class extends Controller {
  static targets = []

  connect() {
    console.log('START PROJECT')
    $('.historic').hide()
  }

  changeColor(e){
    var $target = $(e.target)
    // salva cor original da célula
    var color = $target.css('background-color')
    // seleciona linha desta célula, e logo a seguir todas células (children) da linha selecionada
    var row = $target.closest('tr').children()
    // troca cor da linha
    row.css('background-color', '#BBFFFF')
    // ao sair recupera cor original
    $target.on('mouseout', function(){
      row.css('background-color', color)
    })
  }

  showHistoric(e){
    $('.historic').css('background-color', '#BBFFFF')
    //se a linha estiver oculta, mostre-a, ou vice-versa
    $(e.target).parent().next().toggle()
  }

}
