import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"

const application = Application.start()
const context = require.context("controllers", true, /.js$/)
application.load(definitionsFromContext(context))

import $ from 'jquery'
import {} from 'jquery-ujs'
import 'cocoon-js'
import 'select2'
import 'select2/select2_locale_pt-BR'
import 'bootstrap'

import flatPickr from 'flatpickr'
import { Portuguese } from "flatpickr/dist/l10n/pt"

import moment from 'moment'
window.moment = moment

import Turbolinks from 'turbolinks'
Turbolinks.start()

import 'stylesheets/application'