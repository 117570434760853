import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "my_box" ]

  connect() {
    console.log('START SELECTBOX')

    $(this.my_boxTarget).select2({
    });
  }
}