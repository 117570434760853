import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ ]

  connect() {
    console.log('START AUTO DOWNLOADER')

    $('.auto-download').each(function() {
      var $this = $(this)
      setTimeout(function() {
        window.location = $this.attr('href')
      }, 2000)
    })
  }

}
